<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="发送人:">
        <el-input v-model="form.name" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item label="接收人:">
        <el-input v-model="form.to_name" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item label="邮件名:">
        <el-input v-model="form.email_name" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item label="邮件内容">
        <el-input
            type="textarea"
            :disabled="true"
            autosize
            placeholder="请输入内容"
            v-model="form.descr">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "translateEdit",
  data() {
    return {
      title: '翻译',
      form: {
        name: 'fasongren',
        to_name: 'jieshouren',
        email_name: '邮件名称',
        descr: '撒泼大幅减少【皮蛋粕发酵苏东坡发生的骄傲啤酒哈师大那视频都放假撒打发，pads放假啊山坡地睡觉哦排队交费跑骚的房间埃斯珀i的怕大家覅是，安排今晚i撒PDF模拟盘门票卡瑟芬就爱看，及评审打分跑i的司法解释的建商品房滴哦祭扫配覅没事的金牌大风；帕萨迪房价水平的覅就是怕是大家粉丝破地方明尼苏达颇负三点，安排设计的发射炮弹佛山觉得。',
      },
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '翻译'
      } else {
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped>

</style>