<template>
    <div class="page">
      <div class="emailFucTop">
        <div class="leftView">
          <el-button type="primary">消息箱</el-button>
          <el-button type="primary">已发送</el-button>
          <el-button type="primary">草稿</el-button>
          <el-button type="primary">垃圾箱</el-button>
          <el-button type="primary"  @click="showSendList = true">发送队列</el-button>
        </div>
        <div class="rightView">
          <el-input placeholder="请输入内容" v-model="search" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <div class="emailBody">
        <div class="emailList">
            <div class="noteCard" v-for="i in 20">
              <div class="nameView">
                <span class="name">Name</span>
                <span class="time">Time</span>
              </div>
              <div class="titleView">
                <i class="el-icon-paperclip"></i>
                <span class="title">Titleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeees</span>
              </div>
              <div class="content">Contenttttttttttttttttttttttasdasdasdasdasdasdasttttttttttasdsadtttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt</div>
            </div>
        </div>
        <div class="emailMain">
            <div class="historyView"  v-show="showHistory" >
              <div slot="header" class="cardHeader">
                <span>历史邮件</span>
                <i class="el-icon-s-unfold historySwitchBtn" @click="showHistory = false"></i>
              </div>
              <div class="historyBody">
                <div class="historyItem" v-for="i in 200">
                  <span class="title">Title</span>
                  <span class="time">Time</span>
                </div>
              </div>
            </div>
            <div class="historySwitch" v-show="!showHistory" @click="showHistory = true">
              <i class="el-icon-s-fold historySwitchBtn2"></i>
              <span class="tipsText">展示历史邮件</span>
            </div>
          <div class="customerView">
            <el-avatar class="avatar" size="large" src="https://i.picsum.photos/id/775/200/200.jpg?hmac=grKwOZhdVb2YUnQWMrxmqbZG34qFi-xpX5p52cqm2io"></el-avatar>
            <div class="emailInfo">
              <div class="infoView">
                <div class="send">
                  <span class="name">Name</span>
                  <span class="email">Email</span>
                </div>
                <div class="receive">
                  <span style="margin-right: 5px;opacity: .4;">to</span>
                  <el-link type="primary">Email</el-link>
                </div>
              </div>
              <div class="timeView">
                <span>Date</span>
                <span>Time</span>
              </div>
            </div>
          </div>
          <iframe class="iframeView" id="iframe" src="https://three.dkui.com/topic/mail" width="100%"  frameborder="0" ></iframe>
          <div class="mailFuc">
            <el-button type="primary">快速回复</el-button>
            <el-button type="primary" @click="toSend">正式回复</el-button>
            <el-button type="primary">创建订单</el-button>
            <el-button type="primary">转询价</el-button>
            <el-button type="primary">创建报价单</el-button>
            <el-button type="primary">转同事</el-button>
            <el-button type="primary">邮件轨迹</el-button>
            <el-button type="primary">用户信息(内部)</el-button>
            <el-button type="primary">型号提取</el-button>
            <el-button type="primary">用户信息(挖掘鸡)</el-button>
          </div>
          </div>
      </div>
    <el-drawer
        title="发送队列"
        :visible.sync="showSendList"
        direction="rtl"
        size="25%">
      <el-scrollbar>
        <div class="sendingCard">
          <span class="name">name</span>
          <span class="title">Title</span>
          <div class="content">Content</div>
          <div class="cardBottom">
            <span class="status">Status</span>
            <span class="time">Time</span>
          </div>
          <el-button class="delNote" type="danger" icon="el-icon-delete" circle></el-button>
        </div>
      </el-scrollbar>
    </el-drawer>
  </div>
</template>

<script>
import Edit from './components/translateEdit';
import {apiGetKeywordList} from "@/request/api";

export default {
  name: "extensionlist",
  components: {Edit},
  data() {
    return{
      list:[],
      showSendList:false,
      showHistory:true
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    toSend(){
      this.$router.push('/webmail/redactEmail')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  //margin: 20px;
  height: calc(100vh - 65px  - 40px - 41px);
  .emailFucTop {
    height: 60px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .leftView{}
    .rightView{}
  }
  .emailBody {
    display: flex;
    flex-direction: row;
    height: calc(100% - 70px);
    width: 100%;
    display: flex;
    .emailList {
      background-color: #FFFFFF;
      height: 100%;
      width: 300px;
      margin-right: 10px;
      overflow: scroll;
      .noteCard {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 20px;
        box-sizing: border-box;
        cursor: pointer;
        flex-shrink: 0;
        .nameView {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          .name {
            color: #006eff;
            font-weight: bold;
          }
          .time {
            font-weight: bold;
            opacity: .4;
            font-size: 14px;
          }
        }
        .titleView {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          .title {
            max-width: calc(100% - 16px);
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .content {
          width: 100%;
          height: 35px;
          font-size: 14px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .noteCard:hover,.noteCardOn{
        background-color: #f2f2f3;
      }
    }
    .emailList::-webkit-scrollbar{
      width: 0;
      height: 0;
    }
    .emailMain {
      background-color: #FFFFFF;
      position: relative;
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      box-sizing: border-box;
      .customerView {
        width: 100%;
        height: 100px;
        border-bottom: 1px solid #ebeef5;
        display: flex;
        align-items: center;
        .avatar {
          margin-right: 10px;
          flex-shrink: 0;
        }
        .emailInfo {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .infoView {
            display: flex;
            flex-direction: column;
            align-items: start;
            .send {
              margin-bottom: 5px;
              .name {
                font-weight: bold;
                margin-right: 5px;
              }
              .email {
                font-size: 14px;
                opacity: .4;
              }
            }
          }
          .timeView {
            display: flex;
            flex-direction: column;
            align-items: end;
            opacity: .4;
          }
        }
      }
      .iframeView {
        flex-grow: 1;
      }
      .mailFuc {
        width: 100%;
        height: 120px;
        border-top: 1px solid #ebeef5;
      }
      .historyView{
        position: absolute;
        right: 10px;
        top: 20%;
        width: 260px;
        max-height: 50%;
        box-shadow: 0px 1px 12px rgba(0,0,0,.1);
        background-color: #FFF;
        border: 1px solid #ebeef5;
        display: flex;
        flex-direction: column;

        .cardHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          flex-shrink: 0;
          .historySwitchBtn {
            cursor: pointer;
            font-size: 22px;
          }
          .historySwitchBtn:hover{
            color: #006eff;
          }
        }
        .historyBody {
          overflow: scroll;
          width: 100%;
          height: calc(100% - 60px);
          display: flex;
          flex-direction: column;
          .historyItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            flex-shrink: 0;
            .title{
              width: 100%;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .time {
              width: 100%;
              opacity: 0.4;
              font-size: 14px;
            }
          }
          .historyItem:hover,.historyItemOn{
            background-color: #f2f2f3;
          }

        }
        .historyBody::-webkit-scrollbar{
          width: 0;
          height: 0;
        }

      }
      .historySwitch {
        position: absolute;
        right: 10px;
        top: calc(20% + 9px);
        cursor: pointer;
        background-color: #FFF;
        border: 1px solid #ebeef5;
        padding: 10px;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 12px rgba(0,0,0,.1);
        border-radius: 40px;
        .historySwitchBtn2 {
          font-size: 22px;
        }
        .tipsText {
          width: 0;
          margin-bottom: 2px;
          white-space: nowrap;
          overflow: hidden;
          transition: all 250ms ease-in-out;
        }
      }
      .historySwitch:hover {
        color: #006eff;
        border-radius: 0;
        .tipsText {
          width: 96px;
          margin-left: 10px;
        }
      }
    }
  }

}
.sendingCard {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  .delNote {
    position: absolute;
    top: calc(50% - 20px);
    right: -100px;
    transition: all 500ms ease-in-out;
  }
  .name {
    width: 100%;
    color: #006eff;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  .title {
    width: 100%;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  .content {
    width: 100%;
    height: 35px;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
  }
  .cardBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    opacity: .4;
  }
}
.sendingCard:hover{
  .delNote {
    right: 20px;
  }
}
</style>